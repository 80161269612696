@import '../assets/scss/main.scss';
.projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0;
    &_item {
        align-items: center;
        background-repeat: no-repeat;
        background-position: top;
        background-size: 150%;
        border-radius: 20px;
        box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        height: 256px;
        justify-content: center;
        list-style: none;
        margin: 0 0 24px 0;
        text-align: center;
        transition: background-size 2s, background-position, 2s;
        width: 320px;
        &:hover {
            background-size: 250%;
        }
        &_link {
            display: flex;
            color: $text-color;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;
            text-decoration: none;
            width: 100%;
            &_content {
                background-color: $grey;
                border-radius: 0 0 20px 20px;
                padding: 10px 0;
            }
            &_title {
                font-family: $bold-font;
                margin: 0;
            }
            &_text {
                border-radius: 20px;
                margin: 0;
            }
        }
    }
}