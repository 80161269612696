@import '../assets/scss/main.scss';
.section {
    background-color: #fff;
}
.content {
    @include content;
}
.skills {
    display: grid;
    gap: 30px;
    grid-template-columns: auto auto auto;
    justify-content: center;
    margin: 0 auto;
    max-width: 512px;
    padding: 0;
    &_item {
        align-items: center;
        background-color: $primary-color;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        height: 160px;
        justify-content: center;
        list-style: none;
        text-align: center;
        transition: transform 300ms;
        transition-timing-function: ease-out;
        width: 160px;
        &:hover {
            box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
            transform: scale(1.1);
        }
        &_title {
            font-family: $font;
            margin: 0;
        }
    }
}
@media only screen and (max-width: 545px) {
    .skills {
        grid-template-columns: auto auto;
    }
}
@media only screen and (max-width: 370px) {
    .skills {
        grid-template-columns: auto;
    }
}