@import '../assets/scss/main.scss';
.footer {
    background-color: $grey;
    &_content {
        &_top {
            @include content;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            padding: 30px 0 20px 0;
            &_contact {
                &_h4 {
                    font-size: 22px;
                    margin: 0 0 30px 0;
                }
                &_media {
                    display: flex;
                    &_item {
                        background-color: #000;
                        border-radius: 50%;
                        display: flex;
                        height: 40px;
                        justify-content: center;
                        margin: 0 5px 0 0;
                        width: 40px;
                        &:hover {
                            background-color: $primary-color;
                        }
                    }
                    & span {
                        align-self: center;
                        display: flex;
                    }
                }
            }
            &_menu {
                margin: 0;
                padding: 0;
                &_item {
                    list-style: none;
                    margin: 0 0 10px 0;
                    & a {
                        color: $text-color;
                        text-decoration: none;
                    }
                }
            }
        }
        &_bottom {
            background-color: darken($grey, 20%);
            padding: 20px 0;
            text-align: center;
        }
    }
}