@import '../assets/scss/main.scss';
.content {
    @include content;
}
.contact {
    border-radius: 5px;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    max-width: 415px;
    padding: 50px 0;
    text-align: center;
    &_mail {
        font-family: $black-font;
        font-size: 22px;
    }
    /*&_form {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 350px;
        padding: 0 5px;
        &_input {
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            margin: 0 0 20px 0;
            padding: 10px 30px;
            &:focus {
                border: 1px solid $primary-color;
                outline: none;
            }
        }
        &_submit {
            background-color: $primary-color;
            border:none;
            border-radius: 5px;
            cursor: pointer;
            color: #000;
            font-family: $bold-font;
            padding: 10px 30px;
            &:hover {
                background-color: darken($primary-color, 5%);
            }
        }
    }*/
}