@import '../assets/scss/main.scss';
.intro {
    @include section;
    text-align: center;
    &_btn {
        background-color: $primary-color;
        border: none;
        border-radius: 5px;
        color: $text-color;
        cursor: pointer;
        font-family: $bold-font;
        margin: 0 auto;
        padding: 15px 40px;
        text-decoration: none;
        &:hover {
            background-color: darken($primary-color , 5%);
        }
    }
}
.main-title {
    font-family: $black-font;
    font-size: 64px;
    &_border {
        border-bottom: 10px solid $primary-color;
    }
}
.presentation {
    line-height: 25px;
    margin: 20px auto 20px;
    max-width: 512px;
    text-align: center;
}
@media only screen and (max-width: 768px) {
    .main-title_border {
        font-size: 30px;
    }
}