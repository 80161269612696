@import '../assets/scss/main.scss';
html {
    scroll-behavior: smooth;
}
.App {
    color: $text-color;
    font-family: $font;
    font-size: 16px;
}
.section {
    @include section;
    &_title {
        font-family: $black-font;
        margin: 50px 0;
        text-align: center;
        &_border {
            border-bottom: 10px solid $primary-color;
        }
    }
}
.content {
    @include content;
}
@media only screen and (max-width: 768px) {
    .section {
        padding: 50px 0;
    }
}