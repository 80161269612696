@import '../assets/scss/main.scss';
.header {
    background-color: #fff;
    box-shadow: 0px 5px 5px $grey;
    padding: 20px 0;
    position: fixed;
    width: 100%;
    &_content {
        @include content;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
    }
}
.title {
    font-family: $bold-font;
    font-size:22px;
    margin: 0;
}
.main-menu {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-around;
    margin: 0;
    padding: 0;
    text-align: center;
    &_item {
        list-style: none;
        padding: 0 20px;
        &_link {
            color: $text-color;
            font-family: $bold-font;
            text-decoration: none;
            &:hover {
                color:$primary-color;
            }
        }
    }
}
#hamburger-icon {
    cursor: pointer;
    display: none;
    margin: auto 0;
    & div {
        background-color: #000;
        height: 3px;
        margin: 6px 0;
        transition: 0.4s;
        width: 35px;
    }
}
.mobile-menu {
    display: none;
    height: calc(100vh - 50px);
    left: 0;
    list-style: none;
    padding: 0;
    position: absolute;
    top: 50px;
    width: 100%;
    &_item {
        background-color: #fff;
        border: 1px solid $grey;
        padding: 10px 20px;
        text-align: center;
        width: 100%;
    }
}
.open .mobile-menu {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.open .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}

.open .bar2 {
    opacity: 0;
}
.open .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -8px);
    transform: rotate(45deg) translate(-6px, -8px);
}
@media only screen and (max-width: 768px) {
    header nav {
      display: none;
    }
    #hamburger-icon {
      display: block;
    }
}
  